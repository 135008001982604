<template>
  <DefaultTemplate>
    <div class="depositFunds-box">
      <div class="form-left">
        <div class="content">
          <div class="info-box">
            <p>{{ $t('deposit.unionPayCN.header') }}</p>
            <DepositInfo :instData="instData" :noteData="noteData"></DepositInfo>
          </div>
          <div class="form_main">
            <p class="title">{{ $t('deposit.unionPayCN.form.header') }}</p>
            <el-form
              label-position="top"
              :model="chinaUnionForm"
              ref="chinaUnionForm"
              status-icon
              :rules="chinaUnionRules"
            >
              <div class="form-box">
                <ul class="clearfix">
                  <li>
                    <AccountNumber
                      :supportedCurrenciesList="validCurrencies"
                      @setCurrency="setCurrency"
                      @setAccountNumber="setAccountNumber"
                    ></AccountNumber>
                  </li>
                  <li>
                    <el-form-item :label="setAmtLabel(accountCurrency, chinaUnionForm.amount)" prop="amount">
                      <numeric-input
                        v-model="chinaUnionForm.amount"
                        :currency="accountCurrency"
                        :precision="2"
                      ></numeric-input>
                    </el-form-item>
                  </li>
                </ul>
                <ul class="clearfix">
                  <li class="data">
                    <p>
                      {{ $t('deposit.default.rate.rate', { oldCurrency: 'USD', newCurrency: 'RMB' }) }}
                      <span>{{ rate }}</span>
                    </p>
                    <p>
                      RMB: <span>{{ getRate(rmbChange) }}</span>
                    </p>
                  </li>
                  <li>
                    <el-form-item :label="$t('deposit.unionPayCN.form.holderName')" prop="name" class="name">
                      <el-input
                        v-model="chinaUnionForm.name"
                        :placeholder="cardHoldername"
                        data-testid="cardHoldername"
                      ></el-input>
                      <div class="default_info">{{ $t('deposit.unionPayCN.form.holderNameInfo') }}</div>
                    </el-form-item>
                  </li>
                </ul>
                <ul class="clearfix">
                  <li>
                    <el-form-item :label="$t('common.field.imptNotes')">
                      <el-input v-model="chinaUnionForm.notes" :maxlength="256" data-testid="imptNotes"></el-input>
                    </el-form-item>
                  </li>
                </ul>
              </div>
              <el-form-item>
                <el-button class="btn-blue" :loading="loading" @click="submitForm()" data-testid="submit">
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <div v-html="bankHTML"></div>
      </div>
      <div class="form-right">
        <div class="logo diamond unionpay_china"></div>
      </div>
    </div>
  </DefaultTemplate>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import rounding from '@/util/rounding';
import { autoForm } from '@/util/getAutoForm';
import AccountNumber from '@/components/form/AccountNumber';
import DefaultTemplate from '@/components/template/deposit/DefaultTemplate';
import DepositInfo from '@/components/payment/DepositInfo.vue';
import mixin from '@/mixins/page/deposit';
import { apiGet_holder_name, apiUnionpay_transfer } from '@/resource';

export default {
  name: 'unionPayChina',
  components: { NumericInput, AccountNumber, DefaultTemplate, DepositInfo },
  mixins: [mixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) < this.minLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', {
              minLimit: Math.ceil(this.minLimit),
              currency: this.accountCurrency
            })
          )
        );
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit, currency: this.accountCurrency })
          )
        );
      } else if (this.accountCurrency === 'USC' && parseFloat(value) < this.minLimitByUSC) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', { minLimit: this.minLimitByUSC, currency: this.accountCurrency })
          )
        );
      } else if (this.accountCurrency === 'USC' && parseFloat(value) > this.getMaxUSCByRate(this.maxLimit, this.rate)) {
        callback(
          new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimitByUSC, currency: 'USC' }))
        );
      } else {
        callback();
      }
    };
    return {
      chinaUnionForm: {
        accountNumber: '',
        amount: '',
        name: '',
        notes: ''
      },
      chinaUnionRules: {
        accountNumber: [
          {
            required: true,
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        name: [
          {
            required: true,
            message: this.$t('common.formValidation.cardOnNameReq'),
            trigger: 'blur'
          }
        ]
      },
      validCurrencies: ['USD', 'USC'],
      bankHTML: '',
      rate: 0.0,
      cardHoldername: '',
      instData: [
        this.$t('deposit.reminder.inst1'),
        this.$t('deposit.unionPayCN.inst1'),
        this.$t('deposit.reminder.inst13'),
        this.$t('deposit.unionPayCN.inst2')
      ],
      noteData: [
        this.$t('deposit.unionPayCN.note1'),
        this.$t('deposit.unionPayCN.note2'),
        this.$t('deposit.unionPayCN.note3')
      ]
    };
  },
  computed: {
    rmbChange() {
      return rounding(Math.ceil, this.rate * this.chinaUnionForm.amount, 2);
    }
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.chinaUnionForm.accountNumber = accountNumber;
      this.fetchRate(accountNumber);
    },
    fetchHoldername() {
      apiGet_holder_name().then(resp => {
        if (resp.data.code == 0) {
          this.cardHoldername = resp.data.data;
          this.chinaUnionForm.name = this.cardHoldername;
        }
      });
    },
    submitForm() {
      this.$refs['chinaUnionForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.code == 0) {
                this.bankHTML = autoForm(result.data.data);
                if (this.bankHTML.length > 0) {
                  this.$nextTick(function() {
                    if (document.getElementById('AutoForm')) {
                      document.getElementById('AutoForm').submit();
                    } else {
                      this.loading = false;
                      this.errorMessage(this.$t('deposit.default.failed'));
                    }
                  });
                } else {
                  this.loading = false;
                  this.errorMessage(this.$t('deposit.default.failed'));
                }
              } else {
                this.loading = false;
                if (result.data.code != 562) this.errorMessage(this.$t('deposit.default.failed'));
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    submitDeposit() {
      return apiUnionpay_transfer(
        {
          mt4Account: this.chinaUnionForm.accountNumber,
          operateAmount: this.chinaUnionForm.amount,
          accountName: this.chinaUnionForm.name,
          applicationNotes: this.chinaUnionForm.notes
        },
        this.token
      );
    }
  },
  mounted() {
    this.fetchHoldername();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
